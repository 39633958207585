import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "@/views/Homepage.vue";
import login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "login",
        component: login,
    },
    {
        path: "/Home",
        component: Homepage,
        children: [{
                path: "/configService",
                name: "configService",
                component: () =>
                    import ("@/views/configService"),
            },
            {
                path: "/Dashboard",
                name: "Dashboard",
                component: () =>
                    import ("@/views/Dashboard"),
            },
            {
                path: "/dashboarddealer",
                name: "dashboarddealer",
                component: () =>
                    import ("@/views/DashboardDealer"),
            },
            {
                path: "/CreateOrder",
                name: "CreateOrder",
                component: () =>
                    import ("@/views/CreateOrder"),
            },
            {
                path: "/UpdateOrder",
                name: "UpdateOrder",
                component: () =>
                    import ("@/views/UpdateOrder"),
            },
            {
                path: "/ManageDealer",
                name: "ManageDealer",
                component: () =>
                    import ("@/components/Dealer/ManageDealer.vue"),
            },
            {
                path: "/viewDealer",
                name: "viewDealer",
                component: () =>
                    import ("@/components/Dealer/viewDealer.vue"),
            },
            {
                path: "/editDealer",
                name: "editDealer",
                component: () =>
                    import ("@/components/Dealer/editDealer.vue"),
            },
            {
                path: "/ManageUser",
                name: "ManageUser",
                component: () =>
                    import ("@/views/ManageUser"),
            },
            {
                path: "/viewUser",
                name: "viewUser",
                component: () =>
                    import ("@/components/User/viewUser.vue"),
            },
            {
                path: "/editUser",
                name: "editUser",
                component: () =>
                    import ("@/components/User/editUser.vue"),
            },
            {
                path: "/newUser",
                name: "newUser",
                component: () =>
                    import ("@/components/User/newUser.vue"),
            },
            {
                path: "/ManageAdmin",
                name: "ManageAdmin",
                component: () =>
                    import ("@/components/User/ManageAdmin.vue"),
            },
            {
                path: "/ManageLotto",
                name: "ManageLotto",
                component: () =>
                    import ("@/views/ManageLotto"),
            },
            {
                path: "/CreateLotto",
                name: "CreateLotto",
                component: () =>
                    import ("@/components/Lotto/CreateLotto.vue"),
            },
            {
                path: "/DupplicateLotto",
                name: "DupplicateLotto",
                component: () =>
                    import ("@/components/Lotto/DupplicateLotto.vue"),
            },
            {
                path: "/EditLotto",
                name: "EditLotto",
                component: () =>
                    import ("@/components/Lotto/EditLotto.vue"),
            },
            {
                path: "/ManageTransaction",
                name: "ManageTransaction",
                component: () =>
                    import ("@/views/ManageTransaction"),
            },
            {
                path: "/viewTransaction",
                name: "viewTransaction",
                component: () =>
                    import ("@/components/Transaction/viewTransaction.vue"),
            },
            // {
            //   path: '/CreateCategory',
            //   name: 'CreateCategory',
            //   component: () =>
            //     import('@/components/Category/CreateCategory.vue')
            // },
            // {
            //   path: '/EditCategory',
            //   name: 'EditCategory',
            //   component: () =>
            //     import('@/components/Category/EditCategory.vue')
            // },
            // {
            //   path: '/ManageType',
            //   name: 'ManageType',
            //   component: () =>
            //     import('@/views/ManageType')
            // },
            // {
            //   path: '/CreateType',
            //   name: 'CreateType',
            //   component: () =>
            //     import('@/components/Type/CreateType.vue')
            // },
            // {
            //   path: '/EditType',
            //   name: 'EditType',
            //   component: () =>
            //     import('@/components/Type/EditType.vue')
            // },
            // {
            //   path: '/CreateProduct',
            //   name: 'CreateProduct',
            //   component: () =>
            //     import('@/components/Product/CreateProduct.vue')
            // },
            // {
            //   path: '/ManageProduct',
            //   name: 'ManageProduct',
            //   component: () =>
            //     import('@/views/ManageProduct')
            // },
            // {
            //   path: '/CreateProduct',
            //   name: 'CreateProduct',
            //   component: () =>
            //     import('@/components/Product/CreateProduct.vue')
            // },
            // {
            //   path: '/viewProduct',
            //   name: 'viewProduct',
            //   component: () =>
            //     import('@/components/Product/viewProduct.vue')
            // },
            // {
            //   path: '/EditProduct',
            //   name: 'EditProduct',
            //   component: () =>
            //     import('@/components/Product/EditProduct.vue')
            // },
            // {
            //   path: '/ManageUser',
            //   name: 'ManageUser',
            //   component: () =>
            //     import('@/views/ManageUser')
            // },
            // {
            //   path: '/viewUser',
            //   name: 'viewUser',
            //   component: () =>
            //     import('@/components/User/viewUser.vue')
            // },
            {
                path: "/ManageOrder",
                name: "ManageOrder",
                component: () =>
                    import ("@/views/ManageOrder"),
            },
            {
                path: "/viewOrder",
                name: "viewOrder",
                component: () =>
                    import ("@/components/Order/viewOrder.vue"),
            },
            {
                path: "/EditOrder",
                name: "EditOrder",
                component: () =>
                    import ("@/components/Order/EditOrder.vue"),
            },
            {
                path: "/ManagePowerballOrder",
                name: "ManagePowerballOrder",
                component: () =>
                    import ("@/views/ManagePowerballOrder"),
            },
            {
                path: "/viewPowerballOrder",
                name: "viewPowerballOrder",
                component: () =>
                    import ("@/components/Order/viewPowerballOrder.vue"),
            },
            {
                path: "/EditPowerballOrder",
                name: "EditPowerballOrder",
                component: () =>
                    import ("@/components/Order/EditPowerballOrder.vue"),
            },
            {
                path: "/ManagePowerballblueOrder",
                name: "ManagePowerballblueOrder",
                component: () =>
                    import ("@/views/ManagePowerballblueOrder"),
            },
            // {
            //     path: "/viewPowerballblueOrder",
            //     name: "viewPowerballblueOrder",
            //     component: () =>
            //         import ("@/components/Order/viewPowerballblueOrder.vue"),
            // },
            {
                path: "/EditPowerballblueOrder",
                name: "EditPowerballblueOrder",
                component: () =>
                    import ("@/components/Order/EditPowerballblueOrder.vue"),
            },
            {
                path: "/ManageMegamillionsOrder",
                name: "ManageMegamillionsOrder",
                component: () =>
                    import ("@/views/ManageMegamillionsOrder"),
            },
            // {
            //     path: "/viewMegamillionsOrder",
            //     name: "viewMegamillionsOrder",
            //     component: () =>
            //         import ("@/components/Order/viewMegamillionsOrder.vue"),
            // },
            {
                path: "/EditMegamillionsOrder",
                name: "EditMegamillionsOrder",
                component: () =>
                    import ("@/components/Order/EditMegamillionsOrder.vue"),
            },
            {
                path: "/ManageEurojackpotOrder",
                name: "ManageEurojackpotOrder",
                component: () =>
                    import ("@/views/ManageEurojackpotOrder"),
            },
            // {
            //     path: "/viewEurojackpotOrder",
            //     name: "viewEurojackpotOrder",
            //     component: () =>
            //         import ("@/components/Order/viewEurojackpotOrder.vue"),
            // },
            {
                path: "/EditEurojackpotOrder",
                name: "EditEurojackpotOrder",
                component: () =>
                    import ("@/components/Order/EditEurojackpotOrder.vue"),
            },
            {
                path: "/ManageLotteryjapanOrder",
                name: "ManageLotteryjapanOrder",
                component: () =>
                    import ("@/views/ManageLotteryjapanOrder"),
            },
            // {
            //     path: "/viewLotteryjapanOrder",
            //     name: "viewLotteryjapanOrder",
            //     component: () =>
            //         import ("@/components/Order/viewLotteryjapanOrder.vue"),
            // },
            {
                path: "/EditLotteryjapanOrder",
                name: "EditLotteryjapanOrder",
                component: () =>
                    import ("@/components/Order/EditLotteryjapanOrder.vue"),
            },
            {
                path: "/ManageLaoOrder",
                name: "ManageLaoOrder",
                component: () =>
                    import ("@/views/ManageLaoOrder"),
            },
            {
                path: "/viewLaoOrder",
                name: "viewLaoOrder",
                component: () =>
                    import ("@/components/Order/viewLaoOrder.vue"),
            },
            {
                path: "/EditLaoOrder",
                name: "EditLaoOrder",
                component: () =>
                    import ("@/components/Order/EditLaoOrder.vue"),
            },
            {
                path: "/Managebanners",
                name: "ManageBanner",
                component: () =>
                    import ("@/views/ManageBanner.vue"),
            },
            {
                path: "/createbanners",
                name: "createbanners",
                component: () =>
                    import ("@/components/Banner/Createbanners.vue"),
            },
            {
                path: "/CheckPWBPrizes",
                name: "CheckPWBPrizes",
                component: () =>
                    import ("@/views/CheckPWBPrizes.vue"),
            },
            {
                path: "/CheckPWB_AUSPrizes",
                name: "CheckPWB_AUSPrizes",
                component: () =>
                    import ("@/views/CheckPWB_AUSPrizes.vue"),
            },
            {
                path: "/CheckPWB_MegaPrizes",
                name: "CheckPWB_MegaPrizes",
                component: () =>
                    import ("@/views/CheckPWB_MegaPrizes.vue"),
            },
            {
                path: "/CheckPWB_EuroPrizes",
                name: "CheckPWB_EuroPrizes",
                component: () =>
                    import ("@/views/CheckPWB_EuroPrizes.vue"),
            },
            {
                path: "/CheckPWB_JapanPrizes",
                name: "CheckPWB_JapanPrizes",
                component: () =>
                    import ("@/views/CheckPWB_JapanPrizes.vue"),
            },
            {
                path: "/CheckLaoPrizes",
                name: "CheckLaoPrizes",
                component: () =>
                    import ("@/views/CheckLaoPrizes.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;