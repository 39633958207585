<template>
  <v-row align="center" justify="center">
    <div v-if="!StatusLogin && !OTPpass">
      <v-row align="center" justify="center" no-gutters>
        <v-img src="@/assets/dd_lottery_thai.png" max-width="250px"></v-img>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <h1 class="text-center mt-2" style="color: white">
          ผู้ดูแลระบบ ดีดี ลอตเตอรี่
        </h1>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-card
          class="mt-10 pa-6 text-center"
          style="background-color: #ffffff"
          width="350px"
          height="auto"
        >
          <h1 style="font-weight: 800">กรอกเบอร์มือถือ</h1>
          <span>ระบบจะส่งรหัส OTP เพื่อเข้าระบบ admin</span>
          <v-row class="mt-2" no-gutters justify="center">
            <v-col cols="8" md="8" sm="8">
              <v-text-field
                v-model="telNumber"
                type="number"
                solo
                background-color="white"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2" sm="2">
              <v-btn
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                @click="OTP()"
                >ขอรหัส</v-btn
              >
            </v-col>
          </v-row>
          <!-- <span style="color: white">
          มีเพียงสมาชิกเก่าเท่านั้นที่สามารถเข้าสู่ระบบผ่านเบอร์มือถือได้
          หากเป็นสมาชิกใหม่โปรดสมัครผ่านช่องทางไลน์</span
        > -->
          <!-- <h3 style=" font-weight: 800">หรือ</h3>
        <v-btn color="#02c756" style="color: white" @click="lineLogin()"
          >สมัครสมาชิกด้วยไลน์</v-btn
        > -->
        </v-card>
      </v-row>
    </div>
    <div v-else-if="OTPpass">
      <v-row align="center" justify="center" no-gutters>
        <v-img src="@/assets/dd_lottery_thai.png" max-width="250px"></v-img>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-card
          class="mt-4 pa-6 text-center"
          style="background-color: #ffffff"
          width="350px"
        >
          <h1 style="font-weight: 800">ยืนยันรหัส OTP</h1>
          <span>กรอกรหัส OTP ที่ได้รับจากโทรศัพท์มือถือ</span>
          <v-row class="mt-2" no-gutters justify="center">
            <v-col cols="8" md="8" sm="8">
              <v-text-field
                v-model="otp_code"
                solo
                background-color="white"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" sm="8">
              <v-btn
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                @click="verifyOTP()"
                >ยืนยันหมายเลข OTP</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </div>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import { Encode } from "@/services";
export default {
  data() {
    return {
      telNumber: "",
      StatusLogin: false,
      completeRegister: false,
      OTPpass: false,
      id: "",
      firstname: "",
      lastname: "",
      email: "",
      tel: "",
      api_key: "d551e09b93553c9970e9634a5046cd5a",
      secret_key: "i0rdbEDaUdqyBo3r",
      // project_key: "4K1k8nvj2h",
      project_key: "1b835546c3",
      token: "",
      otp_code: "",
    };
  },
  methods: {
    async OTP() {
      // let formData = new FormData();
      const auth = {
        headers: {
          "Content-Type": "application/json",
          api_key: this.api_key,
          secret_key: this.secret_key,
        },
      };
      const params = {
        project_key: this.project_key,
        phone: this.telNumber,
      };
      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-send`,
        params,
        auth
      );
      console.log("response", response);
      this.token = response.data.result.token;
      console.log("token", this.token);
      if (response.data.code === "000") {
        this.OTPpass = true;
      } else if (this.telNumber == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอกหมายเลขโทรศัพท์",
        });
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "ระบบ OTPขัดข้อง",
        });
      }
    },
    async verifyOTP() {
      const auth = {
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          api_key: this.api_key,
          secret_key: this.secret_key,
          // "Access-Control-Allow-Origin": "*"
        },
      };
      const params = {
        token: this.token,
        otp_code: this.otp_code,
      };
      console.log("param", params);
      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-validate`,
        params,
        auth
      );
      console.log("verify otp success", response);
      if (response.data.code === "000") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "เข้าสู่ระบบเสร็จสิ้น",
        });
        this.StatusLogin = true;
        this.login();
        // this.$router.push("/ManageUser");
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "รหัส OTP ของท่านผิด กรุณากรอกใหม่อีกครั้ง",
        });
      }
    },
    async login() {
      const data = {
        socialId: "",
        telNumber: this.telNumber,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/adminLogin`,
        data
      );
      console.log("response", response);
      if (response.data.response_status === "SUCCESS") {
        const data = {
          token: response.data.data.token,
          user: response.data.data.user,
        };
        localStorage.setItem("DDLotAdmin", Encode.encode(data));
        this.$router.push("ManageUser");
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "ไม่มีข้อมูลผู้ใช้งาน โปรดติดต่อเจ้าหน้าที่",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotAdmin")) {
      this.StatusLogin = true;
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("DDLotAdmin"))
      );
      console.log("userdata", user);
      this.id = user.user.id;
      this.firstname = user.user.firstName;
      this.lastname = user.user.lastName;
      this.tel = user.user.telNumber;
      this.email = user.user.email;
      if (user.user.firstname !== "" || user.user.lastname !== "") {
        // this.completeRegister = true;
        this.$router.push("ManagePowerballOrder");
      }
    }
  },
};
</script>
