<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app width="300px">
        <v-list height="180" class="mt-3">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <!-- <v-avatar size="120">
                <img src="../assets/logo_color.png" />
              </v-avatar> -->
              <v-img
                max-width="250px"
                src="../assets/BKLTH.png"
              ></v-img>
            </a-col>
            <a-col :span="24" class="centerdiv">
              <p style="font-weight: 400; font-size: 18px">{{ username }}</p>
            </a-col>
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <a-menu mode="vertical">
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
          >
            <a-icon :type="item.icon" />
            <span class="pl-5">{{ item.text }}</span>
          </a-menu-item>
        </a-menu>
      </v-navigation-drawer>
      <v-app-bar app color="#1d6eb7">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          style="color: white"
        ></v-app-bar-nav-icon>
        <h3>{{ Header }}</h3>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="Logout"
          >ออกจากระบบ<a-icon type="import" class="ml-2"
        /></v-btn>
      </v-app-bar>
      <v-main class="ma-4">
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      // Powerball US
      // Powerball Aus
      // MegaMillions US
      // EuroJackpot EU
      // Mini Loto JP
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      NavigatorAdmin: [
        // {
        //   key: "dashboard",
        //   text: "Dashboard",
        //   path: "/Dashboard",
        //   name: "Dashboard",
        //   icon: "user",
        // },
        {
          key: "managelotto",
          text: "จัดการลอตเตอรี่",
          path: "/managelotto",
          name: "managelotto",
          icon: "user",
        },
        // {
        //   key: "dashboarddealer",
        //   text: "Dashboardตัวแทน",
        //   path: "/Dashboarddealer",
        //   name: "Dashboarddealer",
        //   icon: "user",
        // },
        {
          key: "menu1",
          text: "จัดการผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
        {
          key: "menu99",
          text: "จัดการแอดมิน",
          path: "/ManageAdmin",
          name: "ManageAdmin",
          icon: "user",
        },
        {
          key: "menu98",
          text: "จัดการแบนเนอร์",
          path: "/Managebanners",
          name: "ManageBanner",
          icon: "user",
        },
        {
          key: "ManageOrder",
          text: "รายการสั่งซื้อ ลอตเตอรี่ไทย",
          path: "/ManageOrder",
          name: "ManageOrder",
          icon: "shopping",
        },

        // {
        //   key: "ManagePowerballOrder",
        //   text: "รายการสั่งซื้อ Powerball US",
        //   path: "/ManagePowerballOrder",
        //   name: "ManagePowerballOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManagePowerballblueOrder",
        //   text: "รายการสั่งซื้อ Powerball Aus",
        //   path: "/ManagePowerballblueOrder",
        //   name: "ManagePowerballblueOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageMegamillionsOrder",
        //   text: "รายการสั่งซื้อ MegaMillions US",
        //   path: "/ManageMegamillionsOrder",
        //   name: "ManageMegamillionsOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageEurojackpotOrder",
        //   text: "รายการสั่งซื้อ EuroJackpot EU",
        //   path: "/ManageEurojackpotOrder",
        //   name: "ManageEurojackpotOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageLotteryjapanOrder",
        //   text: "รายการสั่งซื้อ Mini Loto JP",
        //   path: "/ManageLotteryjapanOrder",
        //   name: "ManageLotteryjapanOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageLaoOrder",
        //   text: "รายการสั่งซื้อ หวยลาว",
        //   path: "/ManageLaoOrder",
        //   name: "ManageLaoOrder",
        //   icon: "shopping",
        // },
        {
          key: "ManageDealer",
          text: "จัดการตัวแทน",
          path: "/ManageDealer",
          name: "ManageDealer",
          icon: "user",
        },
        // {
        //   key: "CheckPWBPrizes",
        //   text: "ตรวจรางวัลPowerball US",
        //   path: "/CheckPWBPrizes",
        //   name: "CheckPWBPrizes",
        //   icon: "dollar",
        // },
        // {
        //   key: "CheckPWB_AUSPrizes",
        //   text: "ตรวจรางวัลPowerball Aus",
        //   path: "/CheckPWB_AUSPrizes",
        //   name: "CheckPWB_AUSPrizes",
        //   icon: "dollar",
        // },
        // {
        //   key: "CheckPWB_MegaPrizes",
        //   text: "ตรวจรางวัล MegaMillions US",
        //   path: "/CheckPWB_MegaPrizes",
        //   name: "CheckPWB_MegaPrizes",
        //   icon: "dollar",
        // },
        // {
        //   key: "CheckPWB_EuroPrizes",
        //   text: "ตรวจรางวัล EuroJackpot EU",
        //   path: "/CheckPWB_EuroPrizes",
        //   name: "CheckPWB_EuroPrizes",
        //   icon: "dollar",
        // },
        // {
        //   key: "CheckPWB_JapanPrizes",
        //   text: "ตรวจรางวัล Mini Loto JP",
        //   path: "/CheckPWB_JapanPrizes",
        //   name: "CheckPWB_JapanPrizes",
        //   icon: "dollar",
        // },
        // {
        //   key: "CheckLaoPrizes",
        //   text: "ตรวจรางวัลลอตเตอรี่ลาว",
        //   path: "/CheckLaoPrizes",
        //   name: "CheckLaoPrizes",
        //   icon: "dollar",
        // },
        {
          key: "configService",
          text: "จัดการระบบ",
          path: "/configService",
          name: "configService",
          icon: "unordered-list",
        },
      ],
      NavigatorDefault: [
        // {
        //   key: "dashboard",
        //   text: "Dashboard",
        //   path: "/Dashboard",
        //   name: "Dashboard",
        //   icon: "user",
        // },
        {
          key: "menu1",
          text: "จัดการผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
        {
          key: "ManageOrder",
          text: "รายการสั่งซื้อ ลอตเตอรี่ไทย",
          path: "/ManageOrder",
          name: "ManageOrder",
          icon: "shopping",
        },

        // {
        //   key: "ManagePowerballOrder",
        //   text: "รายการสั่งซื้อ Powerball US",
        //   path: "/ManagePowerballOrder",
        //   name: "ManagePowerballOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManagePowerballblueOrder",
        //   text: "รายการสั่งซื้อ Powerball Aus",
        //   path: "/ManagePowerballblueOrder",
        //   name: "ManagePowerballblueOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageMegamillionsOrder",
        //   text: "รายการสั่งซื้อ MegaMillions US",
        //   path: "/ManageMegamillionsOrder",
        //   name: "ManageMegamillionsOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageEurojackpotOrder",
        //   text: "รายการสั่งซื้อ EuroJackpot EU",
        //   path: "/ManageEurojackpotOrder",
        //   name: "ManageEurojackpotOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageLotteryjapanOrder",
        //   text: "รายการสั่งซื้อ Mini Loto JP",
        //   path: "/ManageLotteryjapanOrder",
        //   name: "ManageLotteryjapanOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageLaoOrder",
        //   text: "รายการสั่งซื้อ หวยลาว",
        //   path: "/ManageLaoOrder",
        //   name: "ManageLaoOrder",
        //   icon: "shopping",
        // },
        {
          key: "ManageDealer",
          text: "จัดการตัวแทน",
          path: "/ManageDealer",
          name: "ManageDealer",
          icon: "user",
        },
      ],
      NavigatorAccounting: [
        {
          key: "menu1",
          text: "จัดการผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
        {
          key: "ManageOrder",
          text: "รายการสั่งซื้อ ลอตเตอรี่ไทย",
          path: "/ManageOrder",
          name: "ManageOrder",
          icon: "shopping",
        },

        // {
        //   key: "ManagePowerballOrder",
        //   text: "รายการสั่งซื้อ Powerball US",
        //   path: "/ManagePowerballOrder",
        //   name: "ManagePowerballOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManagePowerballblueOrder",
        //   text: "รายการสั่งซื้อ Powerball Aus",
        //   path: "/ManagePowerballblueOrder",
        //   name: "ManagePowerballblueOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageMegamillionsOrder",
        //   text: "รายการสั่งซื้อ MegaMillions US",
        //   path: "/ManageMegamillionsOrder",
        //   name: "ManageMegamillionsOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageEurojackpotOrder",
        //   text: "รายการสั่งซื้อ EuroJackpot EU",
        //   path: "/ManageEurojackpotOrder",
        //   name: "ManageEurojackpotOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageLotteryjapanOrder",
        //   text: "รายการสั่งซื้อ Mini Loto JP",
        //   path: "/ManageLotteryjapanOrder",
        //   name: "ManageLotteryjapanOrder",
        //   icon: "shopping",
        // },
        // {
        //   key: "ManageLaoOrder",
        //   text: "รายการสั่งซื้อ หวยลาว",
        //   path: "/ManageLaoOrder",
        //   name: "ManageLaoOrder",
        //   icon: "shopping",
        // },
        {
          key: "ManageDealer",
          text: "จัดการตัวแทน",
          path: "/ManageDealer",
          name: "ManageDealer",
          icon: "user",
        },
        // {
        //   key: "CheckPWBPrizes",
        //   text: "ตรวจรางวัลพาวเวอร์บอล",
        //   path: "/CheckPWBPrizes",
        //   name: "CheckPWBPrizes",
        //   icon: "dollar",
        // },
        // {
        //   key: "CheckLaoPrizes",
        //   text: "ตรวจรางวัลลอตเตอรี่ลาว",
        //   path: "/CheckLaoPrizes",
        //   name: "CheckLaoPrizes",
        //   icon: "dollar",
        // },
      ],
      Navigator: [],
    };
  },
  created() {
    // รายการสั่งซื้อ จัดการตัวแทน และจัดการผู้ใช้งาน
    // this.Navigator = this.NavigatorDefault;
    var checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("DDLotAdmin"))
    );
    console.log("checkuser", checkuser.user.telNumber);
    // this.username = checkuser.name;
    // console.log("username", this.username);
    if (
      checkuser.user.telNumber == "0873576012" ||
      checkuser.user.telNumber == "0990569595" ||
      checkuser.user.telNumber == "0809454464" ||
      checkuser.user.telNumber == "0985828555" ||
      checkuser.user.telNumber == "0946369942" ||
      checkuser.user.telNumber == "0865057173" ||
      checkuser.user.telNumber == "0826895453"
    ) {
      this.Navigator = this.NavigatorAdmin;
    } else if (checkuser.user.telNumber == "0818668354") {
      this.Navigator = this.NavigatorAccounting;
    } else {
      this.Navigator = this.NavigatorDefault;
    }
    // ออยล์ 098-154-6656
    // น๊อต 082-689-5453
    // โอ๋ 086-505-7173
    // เอิร์ธ 082-553-8685
    // Paanrada 080-945-4464
    // Paanrada 098-582-8555
    // Paanrada 094-636-9942
    // บอย 091-824-9553
    // จิ๊บ 0649855654
    // ไอซ์ 0927728421
    // แบงค์ 091-794-7959
    // อุ้ม 063-649-7464
    // ปุย 090-971-2035
    // ติ๋ม 090-965-6599
    // นา 081-866-8354
    // สุพิศ 088-901-7930
    // นวล 081-931-9256

    this.CheckHeader();
  },
  methods: {
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("DDLotAdmin");
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  overflow: auto;
  width: 100%;
  height: 89vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
