<template>
  <!-- <v-app style="background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%)"> -->
  <v-app style="background-color: #1d6eb7">
    <router-view />
  </v-app>
</template>

<script>
export default {
  methods: {},
};
</script>
<style>
@import "./assets/styles.css";
</style>
